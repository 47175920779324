#login_div{
	background: white;
	position: fixed;
	display: block;
	padding: 20px;
	z-index: 99;
	left: calc(50% - 200px);
	top: 150px;
	height: 400px;
	width: 400px;
}

#forgot_password_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 200px);
	top: 150px;
	height: 300px;
	width: 400px;
}

#reset_password_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 200px);
	top: 150px;
	height: 300px;
	width: 400px;
}

#overlay_gray{
	background: none repeat scroll;
	position: fixed;
	display: none;
	background-color: rgba(0,0,0,0.5);
	z-index: 100;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

#add_user{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 150px);
	top: 150px;
	height: 270px;
	width: 300px;
}

#new_location_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 140px);
	top: 50px;
	height: 400px;
	width: 280px;
}


#travel_statistics_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 70px;
	height: 900px;
	width: 600px;
	overflow-y: hidden;
	overflow-x: hidden;
}

#floor_sounds_div{
	background: white;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 70px;
	height: 600px;
	width: 600px;
	overflow-y: scroll;
	overflow-x: hidden;
}

#floor_images_div{
	background: white;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 70px;
	height: 600px;
	width: 600px;
	overflow-y: scroll;
	overflow-x: hidden;
}

#general_elevator_config_div{
	background: white;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 200px);
	top: 70px;
	height: 600px;
	width: 400px;
	overflow-y: scroll;
	overflow-x: hidden;
}


#add_installation_view{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 100px);
	top: 150px;
	height: 270px;
	width: 200px;
}

#installations_info_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: 200px;
	top: 50px;
	height: 400px;
	width: 280px;
}

#create_job_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: 490px;
	top: 50px;
	height: 400px;
	width: 300px;
}

#show_skills{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 100px);
	top: 150px;
	height: 270px;
	width: 200px;
}

#show_roles{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 150px);
	top: 70px;
	height: 270px;
	width: 300px;
}

#show_pw_change{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 150px);
	top: 150px;
	height: 270px;
	width: 300px;
}

#info_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: 490px;
	top: 50px;
	height: 30px;
	width: 300px;
}

#job_details{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 50px;
	height: 400px;
	width: 600px;
}

#job_reject_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 200px);
	top: 50px;
	height: 400px;
	width: 400px;
}


#search_field{
	background: transparent;
	position: fixed;
	display: block;
	padding: 20px;
	z-index: 1;
	left: 0px;
	bottom: 0px;
	height: 100px;
	width: 200px;
}

#show_schedule{
	background: white;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 100px);
	top: 150px;
	height: 270px;
	width: 200px;
}

#installation_info_div, #sensor_data_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 100px);
	top: 150px;
	height: 270px;
	width: 200px;
}

#show_screens_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 450px);
	top: 70px;
	height: 500px;
	width: 900px;
}

#show_status_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 200px);
	top: 70px;
	height: 500px;
	width: 400px;
}


#select_screens_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 400px);
	top: 70px;
	height: 400px;
	width: 800px;
}

#extra_screen_access_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 500px);
	top: 70px;
	height: 400px;
	width: 1000px;
}

#copy_playlist_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 400px);
	top: 20px;
	height: 400px;
	width: 800px;
}

#screenshot_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 100px;
	height: 400px;
	width: 600px;
}

#camera_view_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 100px;
	height: 400px;
	width: 600px;
}

#qr_preview_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 200px);
	top: 100px;
	height: 500px;
	width: 400px;
}

#create_survey_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 100px;
	height: 400px;
	width: 600px;
}

#create_new_voucher_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 350px);
	top: 100px;
	height: 400px;
	width: 700px;
}

#analyze_survey_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 100px;
	height: 400px;
	width: 600px;
}

#analyze_voucher_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 100px;
	height: 400px;
	width: 600px;
}

#select_device_card{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: 0;
	top: 0px;
	height: 300px;
	width: 400px;
}

#add_from_library{
	background: white;
	position: fixed;
	overflow-y: scroll;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 450px);
	top: 70px;
	height: 700px;
	width: 900px;
}

#add_from_marketplace{
	background: white;
	position: fixed;
	overflow-y: scroll;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 440px);
	top: 70px;
	height: 700px;
	width: 880px;
}

#message_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 100px);
	top: 50px;
	height: 250px;
	width: 400px;
}

#edit_account_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 300px);
	top: 50px;
	height: 400px;
	width: 600px;
}

#error_message_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 100px);
	top: 50px;
	height: 250px;
	width: 400px;
}

#pdfview_div{
	background: transparent;
	position: fixed;
	display: none;
	padding: 20px;
	z-index: 150;
	left: calc(50% - 100px);
	top: 50px;
	height: 297px;
	width: 400px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
	margin:auto;
	padding: 10px;
  animation: spin 2s linear infinite;
}

.cuttext{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 20ch;
}

.library_item_desc{
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	font-style:italic;
	height:45px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}
